import { useContext, useState } from "react";
import { ViewContext } from "./constants/context";
import { slideUp } from "./constants/framer.config";
import { motion } from "framer-motion";
import "./App.css";

import jplogo from "./assets/jupiter-logo.svg";
import img from "./assets/f2.svg";
import mg from "./assets/mg.webp";
import t1 from "./assets/t1.webp";
import t2 from "./assets/t2.webp";
import u1 from "./assets/u1.webp";
import candies from "./assets/candies.png";
import u3 from "./assets/u3.webp";
import u4 from "./assets/u4.webp";
import candybots from "./assets/candybots.jpeg";
import u6 from "./assets/u6.webp";
import x1 from "./assets/x1.webp";
import x2 from "./assets/x2.webp";
// import x3 from "./assets/x3.webp";
// import x4 from "./assets/x4.webp";
// import jup from "./jassets/up.webp";
import log from "./assets/log.webp";
import Navbar from "./components/navbar";
import Carousel from "./components/Carousel";
import governanceSvg from "./assets/governance.svg";
import wheretobuy from "./assets/wheretobuy.png";
import { CarouselProps } from "./types/CarouselProps";

const TOKEN_ADDRESS = "AAqZ6CECabwnYLm3FDqPrVQotXLPGQ3BJSfecYmXeJU5";
function App() {
  const { showView } = useContext(ViewContext);
  const projectItems: CarouselProps["items"] = [
    {
      id: 1,
      name: "Candies",
      img: candies,
      marketLink: "https://magiceden.io/marketplace/candies",
      url: "https://www.candiesnft.xyz/",
      discord: "https://discord.gg/unisin",
      twitter: "https://twitter.com/Candies_NFT",
    },
    {
      id: 2,
      name: "Candy Bots",
      img: candybots,
      marketLink: "https://magiceden.io/marketplace/candybots",
      url: "https://www.candiesnft.xyz/",
      discord: "https://discord.gg/unisin",
      twitter: "https://twitter.com/Candies_NFT",
    },
    {
      id: 3,
      name: "Great Goats",
      img: u4,
      marketLink: "https://magiceden.io/marketplace/great__goats",
      url: "https://greatgoats.io/",
      discord: "https://discord.gg/unisin",
      twitter: "https://twitter.com/GoatsGreat8887",
    },
    {
      id: 4,
      name: "OG DAO",
      img: u1,
      marketLink: "https://magiceden.io/marketplace/og_dao",
      discord: "https://discord.gg/rWgPHTUzGK",
      twitter: "https://twitter.com/Th3OGDAO",
    },
    {
      id: 5,
      name: "Undead Genesis",
      img: u3,
      marketLink: "https://magiceden.io/marketplace/undead_genesis",
      url: "https://undeadgenesis.com/",
      discord: "https://discord.gg/unisin",
      twitter: "https://twitter.com/UndeadGenesis",
    },
    {
      id: 6,
      name: "Undead Relics",
      img: u6,
      marketLink: "https://magiceden.io/marketplace/undeadrelics",
      url: "https://undeadgenesis.com/",
      discord: "https://discord.gg/unisin",
      twitter: "https://twitter.com/UndeadGenesis",
    },
  ];

  const [copyText, setCopyText] = useState("Copy");

  return (
    <div className="app bg-[#060A17]">
      <div className="section w-full max-md:h-[100vh]">
        <Navbar />
        <div className="w-full h-[90%] flex items-center justify-center max-md:h-[100vh]">
          <motion.div
            {...slideUp(showView)}
            className="main flex items-center justify-center h-[80%] max-w-[1500px] max-md:flex-col max-md:h-[100vh]"
          >
            <div className="info max-md:mt-[20rem] fira flex flex-col items-start justify-center gap-5 w-[50%] max-md:w-[80%]">
              <div className="text-[4rem] max-md:text-[2rem] font-extrabold text-white">
                $UNISIN
              </div>
              <div className="text-[3rem] max-md:text-[2rem]  font-extrabold text-white">
                Simplified Cross-Community Engagement on Solana.
              </div>
              <a href="#about">
                <div className="btn butt fira mt-4 text-white py-3 px-[5rem] text-center text-[1.2rem] ">
                  {" "}
                  Learn more
                </div>
              </a>
            </div>

            <img src={img} alt="" className="w-[33rem] mt-[2rem]" />
          </motion.div>
        </div>
      </div>
      <div className="mix max-md:mt-[28rem]">
        <div className="w-full flex items-center justify-center" id="about">
          <motion.div
            {...slideUp(showView)}
            className="second w-full mt-5 flex flex-row items-center py-[10rem] gap-[3rem] justify-center max-md:flex-col-reverse"
          >
            <img src={mg} alt="" />
            <div className="info ml-3 fira flex flex-col items-start justify-center gap-5 w-fit max-w-[50%] max-md:max-w-[90%]">
              <div className="text-[1.5rem] font-normal text-[#53DFFF]">
                About $UNISIN
              </div>
              <div className="text-[3rem] font-extrabold text-white">
                Strength In Numbers
              </div>
              <div className="text-[1rem] font-light text-white">
                Multiple communities, multiple utilities, multiple rewards.
                <br></br>
                <br></br>
                $UNISIN is here to simplify your web3 experience. For all
                projects under the ‘’GOTM’’ umbrella. $UNISIN is the token that
                unites us all.
                <br></br>
                <br></br>
                Rewards accumulated by simply holding your NFTs streamlined into
                one simplified token.{" "}
              </div>
            </div>
          </motion.div>
        </div>

        <motion.div
          {...slideUp(showView)}
          className="ben flex flex-col items-center justify-center gap-5 w-full py-[8rem]"
        >
          <div className="text-[1.5rem] font-normal text-[#53DFFF]">
            Benefits and Features
          </div>
          <div className="text-[3rem] font-bold text-white text-center">
            Advantages of holding $UNISIN
          </div>
          <div className="cards gap-9 mt-[2rem] flex items-center justify-center w-full max-md:flex-col">
            <div className="card w-[20rem] flex flex-col items-center justify-center  text-center gap-5 pt-[3rem] px-[1rem] pb-[8rem]">
              <img src={governanceSvg} alt="" className="log w-[5rem]" />
              <div className="lab fira text-[1.5rem] font-bold text-white">
                Multiple Communities
              </div>
              <div className="cont fira text-[1rem] font-normal text-white">
                $UNISIN serves as a cross-community token utilized across
                multiple projects.
              </div>
            </div>
            <div className="card w-[20rem] flex flex-col items-center justify-center  text-center gap-5 pt-[3rem] px-[1rem] pb-[5rem]">
              <img src={t1} alt="" className="log w-[5rem]" />
              <div className="lab fira text-[1.5rem] font-bold text-white">
                Multiple Utilities
              </div>
              <div className="cont fira text-[1rem] font-normal text-white">
                $UNISIN will integrate seamlessly into various tools and
                features, boosting efficiency and delivering a comprehensive
                solution for users.
              </div>
            </div>
            <div className="card w-[20rem] flex flex-col items-center justify-center  text-center gap-5 pt-[3rem] px-[1rem] pb-[8rem]">
              <img src={t2} alt="" className="log w-[5rem]" />
              <div className="lab fira text-[1.5rem] font-bold text-white">
                Multiple Rewards
              </div>
              <div className="cont fira text-[1rem] font-normal text-white">
                Earn $UNISIN rewards by participating in a variety of events and
                applications.
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          {...slideUp(showView)}
          className="overlay flex flex-col items-center justify-center mt-[10rem] pt-36 pb-[6rem]"
          id="how"
        >
          <img src={wheretobuy} alt="" className="upl w-[15rem] mt-[-17rem]" />
          <div className="where text-black text-center font-bold max-md:scale-[0.6] fira text-[3rem]">
            Where to Buy $UNISIN
          </div>
          <div className="thin text-black font-normal mt-5 mb-5 fira text-[1rem]">
            $UNISIN is available to purchase on all major Dex's
          </div>
          <div className="ca max-md:scale-[0.6] flex pl-8 mt-5 gap-[5rem]">
            <div className="ac text-black font-normal mt-3 mb-3 fira text-[1rem]">
              {TOKEN_ADDRESS}
            </div>
            <div
              className="copy w-[7rem] text-center flex items-center justify-center font-normal cursor-pointer fira gap-3"
              onClick={() => {
                navigator.clipboard.writeText(TOKEN_ADDRESS);
                setCopyText("Copied");
                setTimeout(() => {
                  setCopyText("Copy");
                }, 2000);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_1960)">
                  <path
                    d="M19 2C19.5304 2 20.0391 2.21071 20.4142 2.58579C20.7893 2.96086 21 3.46957 21 4V16C21 16.5304 20.7893 17.0391 20.4142 17.4142C20.0391 17.7893 19.5304 18 19 18H17V20C17 20.5304 16.7893 21.0391 16.4142 21.4142C16.0391 21.7893 15.5304 22 15 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H7V4C7 3.46957 7.21071 2.96086 7.58579 2.58579C7.96086 2.21071 8.46957 2 9 2H19ZM10 15H8C7.74512 15.0003 7.49997 15.0979 7.31463 15.2728C7.1293 15.4478 7.01777 15.687 7.00283 15.9414C6.98789 16.1958 7.07067 16.4464 7.23426 16.6418C7.39786 16.8373 7.6299 16.9629 7.883 16.993L8 17H10C10.2549 16.9997 10.5 16.9021 10.6854 16.7272C10.8707 16.5522 10.9822 16.313 10.9972 16.0586C11.0121 15.8042 10.9293 15.5536 10.7657 15.3582C10.6021 15.1627 10.3701 15.0371 10.117 15.007L10 15ZM19 4H9V6H15C15.5304 6 16.0391 6.21071 16.4142 6.58579C16.7893 6.96086 17 7.46957 17 8V16H19V4ZM12 11H8C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13H12C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11Z"
                    fill="#060A17"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_1960">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {copyText}
            </div>
          </div>
          <a
            href={`https://jup.ag/swap/SOL-${TOKEN_ADDRESS}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="jup butt cursor-pointer gap-4 text-white px-4 py-4 fira flex items-center jusity-center mt-[3rem]">
              <img src={jplogo} alt="" className="jupl" />
              Go to Jup Swap
            </div>
          </a>
        </motion.div>
      </div>

      <motion.div
        {...slideUp(showView)}
        className="comm flex flex-col items-center justify-center fira gap-[3rem] py-[8rem]"
        id="community"
      >
        <div className="text-[1.5rem] font-normal text-[#53DFFF]">
          Communities
        </div>
        <div className="text-[3rem] font-bold text-white text-center">
          Projects Utilizing $UNISIN
        </div>
        <Carousel items={projectItems} />
      </motion.div>

      <motion.div
        {...slideUp(showView)}
        className="bruhh flex flex-col items-center justify-center gap-5 py-[10rem]"
      >
        <div className="text-[1.5rem] font-normal text-[#53DFFF]">
          Social Media
        </div>
        <div className="text-[3rem] font-bold text-white text-center">
          Follow us on Social
        </div>
        <div className="c1 flex items-center justify-center mt-[5rem] gap-5 max-md:flex-col max-md:gap-[4rem]">
          <div className="sc flex flex-col items-center justify-center gap-4">
            <img src={x2} alt="" className="mb-5 mt-[-3rem]" />
            <div className="t text-white text-[1.5rem] fira font-bold mb-5">
              Telegram
            </div>
            <a
              href="https://t.me/UnisinToken"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="fira butt cursor-pointer nn text-[1rem] font-semibold text-center text-white  py-5 w-[18rem]">
                Follow us on Telegram
              </div>
            </a>
          </div>
          <div className="sc flex flex-col items-center justify-center gap-4">
            <img src={x1} alt="" className="mb-5 mt-[-3rem]" />
            <div className="t text-white text-[1.5rem] fira font-bold mb-5">
              X
            </div>
            <a
              href="https://twitter.com/UNISINToken"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="fira nn butt cursor-pointer text-[1rem] font-semibold text-white w-[18rem]  text-center py-5">
                Follow us on X
              </div>
            </a>
          </div>
        </div>
      </motion.div>

      <div className="footer flex flex-col items-center fira justify-center bg-[#040712]">
        <img src={log} alt="" className="mt-[-7rem]" />

        <div className="linkss flex items-center justify-center gap-[5rem] fira text-white text-[1rem] font-semibold max-md:gap-[2.5rem]">
          <a href="#about" className="w-24 text-center hover:opacity-80">
            <div className="">About</div>
          </a>
          <a href="#how" className="w-24 text-center hover:opacity-80">
            <div className="">How to Buy</div>
          </a>
          <a href="#community" className="w-24 text-center hover:opacity-80">
            <div className="">Community</div>
          </a>
          <a href="https://whitepaper.unisin.io/" target="_blank" className="w-24 text-center hover:opacity-80">
            <div className="">Whitepaper</div>
          </a>
        </div>

        {/* <div className="nig py-[2rem] mt-[3rem] flex items-center justify-even w-full px-10  max-md:text-[0.7rem]">
          <div className="clus w-full flex items-center  gap-8 max-md:gap-4">
            <div className="fira text-white text-[1.2rem] max-md:text-[0.7rem] ">
              Follow Us
            </div>
          </div>
          <div className="pp fira w-full text-white flex items-end justify-end font-semi gap-10 max-md:gap-5">
            <div className="img flex  gap-3">
              <a href="https://twitter.com/UNISINToken">
                <img src={x3} alt="" />
              </a>
              <a href="https://twitter.com/UNISINToken">
                <img src={x4} alt="" />
              </a>
            </div>
          </div>
        </div> */}
        <div className="tag fira text-[1rem] fuck text-center py-[3rem] font-bold">
          © Unisin, All Rights Reserved, 2024.
        </div>
      </div>
    </div>
  );
}

export default App;
