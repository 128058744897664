import React, { useState } from "react";
import logo from "../assets/unisin-logo.png";
const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="ct z-10">
      <div className="max-w-7xl mx-auto px-4 z-10 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 z-10">
          {/* Logo on the left */}
          <div className="flex-shrink-0 flex items-center">
            <img src={logo} alt="" className="w-[50px]" />
          </div>
          {/* Hamburger menu button */}
          <div className="flex items-center sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="text-white hover:text-gray-300 focus:outline-none focus:text-gray-300"
            >
              <svg
                className="h-6 w-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm16 4H4v2h16v-2z"
                />
              </svg>
            </button>
          </div>
          {/* Links in the middle */}
          <div className="hidden sm:flex sm:items-center sm:ml-6">
            <div className="flex space-x-4 noto">
              <a
                href="#about"
                className="text-white shit px-3 py-2 rounded-md text-sm font-medium"
              >
                About
              </a>
              <a
                href="#how"
                className="text-white shit px-3 py-2 rounded-md text-sm font-medium"
              >
                How To Buy
              </a>
              <a
                href="#community"
                className="text-white shit px-3 py-2 rounded-md text-sm font-medium"
              >
                Community
              </a>
              <a
                href="https://whitepaper.unisin.io/"
                target="_blank"
                className="text-white shit px-3 py-2 rounded-md text-sm font-medium"
              >
                Whitepaper
              </a>
            </div>
          </div>
          {/* Social links on the right */}
          <div className="hidden sm:flex sm:items-center">
            <div className="flex space-x-4">
              <a href="https://t.me/UnisinToken" className="group">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-gray-600 group-hover:fill-gray-300 duration-75"
                >
                  <g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.9801 2.03135C20.0235 1.72305 19.9955 1.40675 19.899 1.11543C19.8025 0.824107 19.641 0.568414 19.4314 0.374993C19.2217 0.181571 18.9716 0.0574986 18.7071 0.0157023C18.4426 -0.0260939 18.1733 0.0159151 17.9274 0.137353L0.912456 8.52407C-0.273615 9.10881 -0.334311 11.117 0.912456 11.7227C2.147 12.3254 3.39954 12.8763 4.66768 13.3744C5.82987 13.824 7.09754 14.2399 8.11346 14.3575C8.39107 14.7466 8.74032 15.1216 9.09654 15.4641C9.64082 15.9883 10.2955 16.5276 10.9722 17.0447C12.3274 18.0814 13.8309 19.0727 14.8438 19.718C16.0548 20.4868 17.5045 19.6015 17.7214 18.0558L19.9801 2.03135ZM2.81992 10.1117L17.8687 2.69414L15.7612 17.6505C14.7672 17.018 13.3354 16.071 12.0597 15.0948C11.4718 14.653 10.9042 14.1751 10.3592 13.6633C10.2136 13.524 10.0722 13.3787 9.93535 13.2276L13.8766 8.61493C14.0634 8.39652 14.1683 8.10022 14.1684 7.79123C14.1685 7.48224 14.0637 7.18586 13.8771 6.96729C13.6906 6.74872 13.4375 6.62587 13.1735 6.62576C12.9096 6.62565 12.6564 6.74829 12.4697 6.96671L8.15425 12.0185C7.4239 11.909 6.40699 11.5922 5.29057 11.1612C4.45942 10.8371 3.6359 10.4868 2.82092 10.1106L2.81992 10.1117Z"
                      //   fill="#42E8E0"
                    />
                  </g>
                </svg>
              </a>
              <a
                href="https://twitter.com/UNISINToken"
                target="_blank"
                className="group"
                rel="noreferrer"
              >
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-gray-600 group-hover:fill-gray-300 duration-75"
                >
                  <g>
                    <path d="M17.3259 0H20.6992L13.3293 8.47278L22 20H15.2112L9.8945 13.0079L3.80967 20H0.4345L8.31783 10.9372L0 0.000921999H6.96117L11.7673 6.39192L17.3259 0ZM16.1425 17.9699H18.0116L5.9455 1.92412H3.93983L16.1425 17.9699Z" />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`${isOpen ? "hidden" : "block"} sm:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1 mew">
          <a
            href="#about"
            className="text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700"
          >
            About
          </a>
          <a
            href="#how"
            className="text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700"
          >
            How To Buy
          </a>
          <a
            href="#community"
            className="text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700"
          >
            Community
          </a>
          <a
            href="https://whitepaper.unisin.io/"
            target="_blank"
            className="text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700"
          >
            Whitepaper
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
